//Login.vue
<template>
  <div class="row justify-content-md-center mainContainer">
    <div class="col-md-6">
        <img width="250px" alt="Proban Logo" class="img-fluid" src="../assets/images/probanlogo.jpeg">

      <div class="card">
        <div class="card-header">Proban Online Ordering Platform</div>
        <div class="card-body">
          <div class="alert alert-danger" v-if="errors" v-html="errors"></div>
          <form @submit.prevent="userLogin">
            <div class="form-group">
              <label for="email">Username</label>
              <input v-model="form.username" type="email" class="form-control" required placeholder="Username">
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input v-model='form.password' type="password" class="form-control" required placeholder="Password">
            </div>
            <a href="#" style="float:right;" @click="showModal = true">Forgot Password?</a><br>
            <button type="submit" class="btn btn-success text-white">Sign In</button>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Forgot Your Password?</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="showModal = false">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Please contact HQ Admin to reset your password.</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="showModal = false">Close</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import Helper from '../../utils/Helper'
import UserServices from '@/service/UserServices'

/*
  TODO for dev only harus di hapus
  email admin
  admin@pro-ban.com
  Proban2015!!#
  Proban2021!!#

   email merchant
   merchant1@pro-ban.com
   Proban2015!!#
   Proban2021!!#
 */

export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      errors: null,
      isError: false,
      showModal: false
    }
  },
  methods: {
    ...mapMutations('auth', ['reset_user', 'set_user']),
    async userLogin () {
      this.message = ''
      try {
        const res = await UserServices.login(this.form)
        if (res) {
          const { data } = res
          if (data) {
            const { token } = data
            if (token) {
              this.$cookies.set('token', token)
              const userDataRes = await UserServices.getMe()
              if (userDataRes) {
                const { data } = userDataRes
                if (data.roles.indexOf('customer') !== -1) {
                  this.reset_user()
                  this.errors = 'You are not allowed to login here'
                } else {
                  this.set_user(data)
                  await this.$router.push('/dashboard')
                }
              }
            }
          }
        }
      } catch (e) {
        let message = 'Something Went Wrong'
        console.log(e.response.data.error.message)
        if (e && e.response && e.response.data && e.response.data.error) message = Helper.generateErrorMessage(e.response.data.error.message)
        this.errors = message
        this.isError = true
      }
    }
  },
  mounted () {
    const token = this.$cookies.get('token')
    if (token) this.$router.push('/dashboard')
  }
}
</script>
<style lang="scss" scoped>
  .mainContainer{
    background-color:#fff;
    min-height:800px;
  }
  .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
